import { useEffect, useState } from 'react';
import axios from '../../axios/axios';

export const useValueList = (data,
    dataSource,
    parentValue,
    reloadOnParentChange, emptyOption) => {
    const [list, setList] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const fetchData = () => {
            axios({
                method: 'GET',
                params: {
                    selectedId: data
                },
                baseURL: dataSource + (parentValue ? parentValue : '')
            })
                .then(response => {
                    if (response) {
                        const result = response.data;
                        if (result && emptyOption) {
                            result.unshift({ value: '', text: '' });
                            if (result?.data?.length) {
                                setList(result.data.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value));
                            } else {
                                setList(result.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value));
                            }
                        }
                        if (result && (isMounted)) {
                            if (result?.data?.length) {
                                setList(result.data.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value));
                            } else {
                                setList(result.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value));
                            }
                        }
                    }
                }).catch(error => {
                    console.error(error);
                });
        };
        if (typeof dataSource === 'string') {
            fetchData();
        }
        else if (dataSource) {
            setList(dataSource);
        }
        return () => { isMounted = false };
    }, [parentValue, dataSource, data, reloadOnParentChange,emptyOption]);
    return [list, setList];
};


export const useValueListWithCache = (data,
    dataSource,
    parentValue,
    setList, list, reloadOnParentChange, valueKey) => {
    useEffect(() => {
        let isMounted = true;
        const fetchData = () => {
            if (list[valueKey]) {
                return;
            }
            axios({
                method: 'GET',
                params: {
                    selectedId: data
                },
                baseURL: valueKey
            })
                .then(response => {
                    if (response) {
                        const result = response.data;
                        if (result && (isMounted || reloadOnParentChange)) {
                            setList(valueKey, result.filter(i => (!i.archived && !i.archiveDate && !i.inactive) || result === i.value));
                        }
                    }
                }).catch(error => {
                    console.error(error);
                });
        };
        if (typeof dataSource === 'string') {
            fetchData();
        }
        else if (dataSource) {
            setList(valueKey, dataSource);
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentValue, dataSource, data, reloadOnParentChange, valueKey
            , setList
    ]);
};
