import React from 'react';
import { Select } from 'antd';
import { CssErrorClassHandling } from '../helper/element';
import { ValidationErrorClass } from '../helper/validation';
import { getActionArgument } from './util/dropDown';
import { useEffect } from 'react';

const { Option } = Select;

const DropDownElement = props => {
    var firstTimeAction = props.firstTimeAction;
    var data = props.data;
    var action = props.action;
    let newValueList = props.valueList;
    useEffect(() => {
        if (firstTimeAction) {
            firstTimeAction(newValueList.find(i => i.value === data));
        }
    }, [firstTimeAction, newValueList, data]);

    if (props.defaultValue && !data) {
        const event = {
            target: ''
        };
        setTimeout(() => {
            props.action(getActionArgument(props.valueList, props.defaultValue, props.mode, event));
        }, 0);
    }

    
    
    if (props.selectedValue && newValueList) {
        props.mode === 'multiple' ? (JSON.parse(props.selectedValue).forEach(slot => !newValueList.some(obj => obj.value === slot.value) && newValueList.push({ value: slot.value, text: slot.text })))
            : !newValueList.some(slot => slot.value === data) && newValueList.push({ value: data, text: props.selectedValue });
    }

    return <Select
        mode={props.mode}
        placeholder={props.placeholder}
        showSearch={props.showSearch}
        value={props.valueList ?
            (props.mode === 'multiple' ?
                (!data || data.trim() === '' ? [] :
                    data.split(',').map(Number)) :
                ((data !== null && data !== undefined) ? data || props.defaultValue : props.defaultValue)) : (props.mode === 'multiple' ? [] : '')}
        className={CssErrorClassHandling(ValidationErrorClass, props.classes, props.valudationMessages).join(' ')}
        style={props.style}
        disabled={!props.condition || !props.valueList}
        id={props.id}
        name={props.name}
        allowClear={props.allowClear}
        defaultValue={props.defaultValue}
        dropdownRender={props.dropdownRender}
        onChange={(value, event) => action(getActionArgument(props.valueList, value, props.mode, event))}
        optionFilterProp="children"
        filterOption={props.filterOption ? props.filterOption : (input, option) => {
            let item = option.children;
            if (typeof item === 'object') {
                item = item.props.children;
            }
            return item.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}>
        {
            newValueList && newValueList.map((item, i) =>
                <Option {...item.options} key={i} value={item.value || item.id} text={item.text || item.name}>
                    {item.favourite ? <b>{item.text}</b> : item.text || item.name}
                </Option>
            )
        }
    </Select>
};


export default DropDownElement;