import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import RevisionOrderAdmin from '../../RevisionOrderAdmin/RevisionOrderAdminSpreadsheet';
import { getContentWidth } from '../../../../../store/reducers/revisionOrderAdmin/helper/general';
import { getCustomerOrderAdmin, setNewRequestOrdersCount } from '../../../../../store/actions/customerOrderAdmin/customerOrderAdmin';
import { useClientHubNewRequest } from "../../../../hook/hub/useClientHubNewRequest";
import './index.css';

const RevisionOrderAdminBuild = props => {
  const { state, total, newRequestCount, setNewRequestOrdersCount} = props
  const resource = 'revisionOrderAdmin';
  const headers = state[resource].headers;
  const width = getContentWidth(headers) ;
  const pathname = window.location.pathname;
  let count = newRequestCount ? newRequestCount : total;
  if (newRequestCount < total) {
    count = total;
  };

  useClientHubNewRequest((message) => setNewRequestOrdersCount(resource, message.newRequestCount), 'notification');

  useEffect(() => {
    getCustomerOrderAdmin('customerOrderAdmin');
}, [newRequestCount, total]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: '0 12px 6px',
        alignSelf: 'center',
        width: '100%',
        maxWidth: width + 20,
      }}
      className="pane"
      >
      <RevisionOrderAdmin {...props} resource={resource} />
    </div>
  );
};
const mapStateToProps = state => ({
  state: state,
  newRequestCount: state.customerOrderAdmin.newRequestCount,
  total: state.customerOrderAdmin.total,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerOrderAdmin: (resource, endPoint) => dispatch(getCustomerOrderAdmin(resource, endPoint)),
  setNewRequestOrdersCount: (resource, newRequestCount) => dispatch(setNewRequestOrdersCount(resource, newRequestCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RevisionOrderAdminBuild);
