import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CustomerOrderAdmin from '../../CustomerOrderAdmin/CustomerOrderAdminSpreadsheet';
import { getContentWidth } from '../../../../../store/reducers/customerLastOrders/helper/general';
import { getCustomerOrderAdmin, setNewRequestOrdersCount } from '../../../../../store/actions/customerOrderAdmin/customerOrderAdmin';
import { useClientHubNewRequest } from "../../../../hook/hub/useClientHubNewRequest";
import './index.css';

const CustomerOrderAdminBuild = props => {
  const { state, total, newRequestCount, setNewRequestOrdersCount } = props
  const resource = 'customerOrderAdmin';
  const headers = state[resource].headers;
  const width = getContentWidth(headers);

  useClientHubNewRequest((message) => setNewRequestOrdersCount(resource, message.newRequestCount), 'notification');

  useEffect(() => {
    getCustomerOrderAdmin('customerOrderAdmin');
}, [newRequestCount, total]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: '0 12px 6px',
        alignSelf: 'center',
        width: '100%',
        maxWidth: width + 20,
      }}
      className="pane"
      >
      <CustomerOrderAdmin {...props} resource={resource} />
    </div>
  );
};

const mapStateToProps = state => ({
  state: state,
  newRequestCount: state.customerOrderAdmin.newRequestCount,
  total: state.customerOrderAdmin.total,
  loading: state.customerOrderAdmin.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerOrderAdmin: (resource, endPoint) => dispatch(getCustomerOrderAdmin(resource, endPoint)),
  setNewRequestOrdersCount: (resource, newRequestCount) => dispatch(setNewRequestOrdersCount(resource, newRequestCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderAdminBuild);
