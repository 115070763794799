import React from 'react';
import moment from 'moment';
import { Action } from './action.config';
import { ElementConditions, Ability } from './condition.config';
import { localDate } from '../../codeless/helper/common';

import {
    GenerateInput,
    GenerateFileInput,
    GeneratePhoneInput,
    GenerateDecimalInput,
    GeneratePassword,
    GenerateTextArea,
    GenerateCheckBox,
    GenerateDatePicker,
    GenerateTimePicker,
    GenerateToolBarButton,
    GenerateColumnButton,
    GenerateRadio,
    GenerateInputCurrency,
    GenerateCheckBoxModern,
    ElementClassNames,
    GenerateDueDatePicker
} from '../helper/element';
import { FindParentValue, FindSelectedValueText, FunctionArgumentNames } from '../helper/common';

import { Defines, bytesToSize } from '../../project/Defines';
import {
    GenerateColumnDate,
    GenerateLatestCommentIndicator,
    GenerateReceiveIndicator,
    GenerateSimpleColumnIndicator,
    GenerateCancelIndicator,
    GenerateColumnDateLabel,
    GenerateColumnDateTimeLabel,
    GenerateColumnBoolLabel,
    GenerateColumnBadge,
    GenerateColumnTimeLabel
} from '../helper/list';
import AddressField from '../elements/addressField';
import MapField from '../elements/mapField';
import MultipleMarkersMapField from '../elements/multipleMarkersMapField';
import SelectElementAdd from '../elements/selectElementAdd';
import SelectElementAddWithCache from '../elements/selectElementAddWithCache';
import AvatarUploader from '../elements/avatarUploader';
import Download from '../elements/download';
import ColorElement from '../elements/colorElement';
import SelectElement from '../elements/selectElement';
import SelectElementWithCache from '../elements/selectElementWithCache';

const DefaultParams = {
    displayability: Ability.Displayability,
    editability: Ability.Editability,
    min: null,
    max: null,
    date_format: Defines.Format.PrintDate,
    classes: [],
    style: {},
    condition: ElementConditions.Enable,
    placeholder: null,
    label: '',
    defaultValue: null,
    array: [],
    item: [],
    showHidePassword: false,
    name: '',
    timeFormat: Defines.Format.MomentTime,
    minuteStep: 15,
    use12Hours: false,
    dataSource: null,
    emptyOption: false,
    parentValueType: null,
    reloadOnParentChange: false,
    addparams: null,
    title: '',
    icon: '',
    dataTitle: true,
    text: '',
    attributes: [],
    fileFieldName: '',
    width: 0,
    height: 0,
    display: null,
    fieldName: '',
};


export const Development_GenerateFunctionArguments = () => {
    for (const et in ElementTypes) {
        if (typeof ElementTypes[et] === 'function' && et !== 'Generic') {
            console.log(et);
            const argNames = FunctionArgumentNames(ElementTypes[et]);
            console.log(argNames);
            console.log('___________________________________________');
        }
    }
};

export const ElementTypesArguments = {
    TableLabelField: ["displayability", "condition", "classes", "style", "display"],
    LandingText: [],
    TableDecimalField: ["displayability", "condition", "classes", "style", "display"],
    TableFileSizeLabelField: ["displayability", "condition", "classes", "style"],
    DateLabel: ["displayability", "condition"],
    DateTimeLabel: ["displayability", "condition"],
    ColumnDate: ["displayability", "condition"],
    ColumnDateTime: ["displayability", "condition"],
    ColumnBool: ["displayability", "condition"],
    PasswordField: ["showHidePassword", "displayability", "editability", "classes", "style", "condition", "placeholder"],
    LabelField: ["displayability", "condition", "classes", "style", "display"],
    LabelDecimalField: ["displayability", "condition", "classes", "style", "display"],
    LabelFieldDate: ["displayability", "condition", "classes", "style", "display"],
    FileSizeLabelField: ["displayability", "condition", "classes", "style"],
    LabelCountField: ["array", "item", "displayability", "condition", "style", "classes"],
    TextField: ["displayability", "editability", "classes", "style", "condition", "placeholder", "withTooltip"],
    IntField: ["displayability", "editability", "classes", "style", "condition", "placeholder"],
    IntDecimalField: ["displayability", "editability", "classes", "style", "condition", "placeholder"],
    IntFieldViewOrEdit: ["displayability", "editability", "classes", "style", "condition", "placeholder", "display"],
    DecimalFieldViewOrEdit: ["displayability", "editability", "classes", "style", "condition", "placeholder", "display"],
    CheckBox: ["label", "displayability", "editability", "classes", "style", "condition"],
    CheckBoxModern: ["label", "defaultValue", "displayability", "editability", "classes", "style", "condition"],
    Radio: ["label", "name", "displayability", "editability", "classes", "style", "condition", "field"],
    DateFieldViewOrEdit: ["displayability", "editability", "min", "max", "date_format", "classes", "style", "condition"],
    TimeFieldViewOrEdit: ["displayability", "editability", "classes", "style", "condition", "placeholder"],
    DateField: ["displayability", "editability", "min", "max", "date_format", "classes", "style", "condition", "fieldName"],
    DueDateField: ["displayability", "editability", "min", "max", "date_format", "classes", "style", "condition", "fieldName", "hardFieldName"],
    TimeField: ["displayability", "editability", "timeFormat", "minuteStep", "use12Hours", "classes", "style", "condition"],
    CurrencyField: ["displayability", "editability", "classes", "style", "condition", "placeholder", "fieldName"],
    CurrencyFieldViewOrEdit: ["displayability", "editability", "classes", "style", "condition", "placeholder", "display"],
    AddressField: ["displayability", "editability", "classes", "style", "condition"],
    MapField: ["displayability", "editability", "classes", "style", "condition", "placeholder"],
    Comment: ["displayability", "editability", "classes", "style", "condition", "placeholder"],
    Select: ["dataSource", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "selectedValueType"],
    SelectViewOrEdit: ["dataSource", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "display"],
    SelectWithCache: ["dataSource", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "selectedValueType"],
    MultipleSelect: ["dataSource", "parentValueType", "displayability", "editability", "classes", "style", "condition", "selectedValueType"],
    Combo: ["dataSource", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "selectedValueType", "fieldName", "defaultValue", "search"],
    ComboViewOrEdit: ["dataSource", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "display"],
    ComboAdd: ["dataSource", "addparams", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "defaultValue", "selectedValueType", "emptyOption", "addNewElement"],
    ComboAddViewOrEdit: ["dataSource", "addparams", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "display"],
    ComboAddViewOrEditWithCash: ["dataSource", "addparams", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "display", "selectedValueType"],
    ComboAddWithCache: ["dataSource", "addparams", "parentValueType", "reloadOnParentChange", "displayability", "editability", "classes", "style", "condition", "selectedValueType"],
    ToolBarButton: ["title", "icon", "displayability", "editability", "classes", "style", "condition"],
    CancelColumnIndicator: ["displayability", "condition", "dataTitle", "attributes", "display"],
    LatestCommentIndicator: ["displayability", "condition", "dataTitle", "attributes", "display"],
    ColumnBadge: ["displayability", "condition", "attributes", "styles"],
    SimpleColumnIndicator: ["displayability", "condition", "dataTitle", "text", "attributes", "display"],
    ReceiveIndicator: ["displayability", "condition", "dataTitle", "attributes", "display"],
    Avatar: ["fileFieldName", "displayability", "editability", "condition", "noImage"],
    Download: ["fileFieldName", "width", "height", "displayability", "condition"],
    Color: ["displayability", "editability", "classes", "style", "condition"],
    ToolbarDate: ["displayability", "condition"],
    ColumnTime: ["displayability", "condition"],
    LabelFieldCurrency: ["displayability", "condition", "classes", "style", "display"]
};

export const getNormalizedAttributes = (attributes, object, data) => {
    const attributeList = {};

    if (attributes) {
        for (const a in attributes) {
            attributeList[a] = {};
            for (const n in attributes[a]) {
                let value = attributes[a][n];
                if (typeof (value) === 'function') {
                    value = value(object, data);
                }
                if (value !== null) {
                    if (n === 'style') {
                        attributeList[a][n] = value;
                    } else {
                        attributeList[a][n] = `${value}`;
                    }
                }
            }
        }
    }

    return attributeList;
};

export const ElementTypes = {
    Params: {
        displayability: 'displayability',
        editability: 'editability',
        min: 'min',
        max: 'max',
        date_format: 'date_format',
        classes: 'classes',
        style: 'style',
        condition: 'condition',
        placeholder: 'placeholder',
        label: 'label',
        defaultValue: 'defaultValue',
        array: 'array',
        item: 'item',
        showHidePassword: 'showHidePassword',
        name: 'name',
        timeFormat: 'timeFormat',
        minuteStep: 'minuteStep',
        use12Hours: 'use12Hours',
        dataSource: 'dataSource',
        emptyOption: 'emptyOption',
        parentValueType: 'parentValueType',
        reloadOnParentChange: 'reloadOnParentChange',
        selectedValueType: 'selectedValueType',
        addparams: 'addparams',
        title: 'title',
        icon: 'icon',
        dataTitle: 'dataTitle',
        text: 'text',
        attributes: 'attributes',
        fileFieldName: 'fileFieldName',
        width: 'width',
        height: 'height',
        display: 'display',
        fieldName: 'fieldName',
    },
    Generic: (elementType, params) => {
        let errorText = '';
        let hasError = false;
        let userProvidedFunctionName = elementType.toString();
        if (!elementType) {
            errorText = "Fatal error: elementType can't be ampty";
            hasError = true;
        }
        if (typeof elementType === 'string') {
            elementType = ElementTypes[elementType];
        }
        if (elementType === undefined && !hasError) {
            errorText = `Fatal error: elementType '${userProvidedFunctionName}' function not found`;
            hasError = true;
        }
        if (typeof elementType === 'function') {
            if (elementType === ElementTypes.Generic && !hasError) {
                errorText = "Fatal error: Module can't be element type function";
                hasError = true;
            }
            if (!hasError) {
                if (!params) {
                    params = {};
                }
                if (Array.isArray(params)) {
                    const newParams = {};
                    for (const p in params) {
                        const pN = params[p];
                        if (!Array.isArray(pN) || pN.length < 2) {
                            errorText = "Fatal error: Params array item should be array with 2 items";
                            hasError = true;
                            break;
                        }
                        newParams[pN[0]] = pN[1];
                    }
                    params = newParams;
                }
                if (!hasError) {
                    params = {
                        ...DefaultParams, ...params
                    };
                    const argNames = ElementTypesArguments[elementType.name];//FunctionArgumentNames(elementType);
                    const functionArguments = [];
                    for (const argI in argNames) {
                        functionArguments.push(params[argNames[argI]]);
                    }
                    let contentFunction;
                    try {
                        contentFunction = elementType.apply(this, functionArguments);
                    }
                    catch (error) {
                        errorText = error;
                        hasError = true;
                    }
                    if (contentFunction) {
                        return contentFunction;
                    }
                    errorText = 'Content function not created';
                    hasError = true;
                }
            }
        }
        else if (!hasError) {
            errorText = "Fatal error: elementType should be a function";
            hasError = true;
        }
        console.error(errorText);
        return () => () => <span style={{ color: 'red' }}>{errorText}</span>
    },

    //sheet specific label fields
    TableLabelField: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}, display = null) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                    {display && typeof display === 'function' ? display(object, data) : data}
                </text> : null,

    LandingText: () => data => () => {
        switch (data) {
            case 1:
                return 'MON';
            case 2:
                return 'TUES';
            case 3:
                return 'WED';
            case 4:
                return 'THURS';
            case 5:
                return 'FRI';
            case 6:
                return 'SAT';
            case 7:
                return 'SUN';
            default:
                return '';
        }
    },

    TableDecimalField: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}, display = null) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>{display && typeof display === 'function' ? display(object, data) : data?.toLocaleString()}</text> : null,

    TableFileSizeLabelField: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                    {data ? bytesToSize(data) : '-'}
                </text> : null,
    DateLabel: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {data ? moment(data).format(Defines.Format.PrintDate) : ''}
                    </span>
                </div>
                : null,
    ColumnDate: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateColumnDateLabel(
                    data)
                : null,

    ColumnDateTime: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateColumnDateTimeLabel(
                    data)
                : null,

    DateTimeLabel: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {data ? localDate(data, Defines.Format.PrintDateTime) : ''}
                    </span>
                </div>
                : null,

    ColumnTime: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateColumnTimeLabel(
                    data)
                : null,

    ColumnBool: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateColumnBoolLabel(
                    data)
                : null,

    //using in login page only
    PasswordField: (showHidePassword = false, displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, onKeyPress) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ? (
                showHidePassword ?
                    <GeneratePassword
                        condition={condition}
                        data={data}
                        classes={ElementClassNames(object, data, classes)}
                        action={action}
                        out={out}
                        valudationMessages={valudationMessages}
                        placeholder={placeholder}
                        onKeyPress={onKeyPress}
                        style={style}
                    />
                    : GenerateInput('password',
                        editability(object),
                        ref,
                        data,
                        ElementClassNames(object, data, classes),
                        action,
                        out,
                        valudationMessages,
                        placeholder,
                        null,
                        null,
                        null,
                        style))
                : null,

    //form fields
    LabelField: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}, display = null) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {display && typeof display === 'function' ? display(object, data) : data}
                    </text>
                </div>
                : null,

    LabelDecimalField: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}, display = null) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {display && typeof display === 'function' ? display(object, data) : data?.toLocaleString()}
                    </text>
                </div>
                : null,

    LabelFieldDate: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}, display = null) =>
        (data, object) => () => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                return <div className="data_read">
                    <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {display && typeof display === 'function' ? display(object, data) : data ? moment(data).format(Defines.Format.PrintDate) : null}
                    </span>
                </div>;
            }

            return null;
        },

    LabelFieldCurrency: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}, display = null) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {display && typeof display === 'function' ? display(object, data) : data ? data.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        }) : null}
                    </span>
                </div>
                : null,

    FileSizeLabelField: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable, classes = [], style = {}) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {data ? bytesToSize(data) : '-'}
                    </span>
                </div>
                : null,

    LabelCountField: (array, item, displayability = Ability.Displayability,
        condition = ElementConditions.Enable, style, classes) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                        {object[array] && object[array].reduce((a, b) => {
                            return a + (b[item] ? parseInt(b[item]) : 0);
                        }, 0)}
                    </span>
                </div>
                : null,

    TextField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder,
        withTooltip,
        fieldName,
    ) =>
        (data, object, action, out, ref, onKeyPress, attributes) => (valudationMessages, warningMessages) => {
            if (fieldName === 'SO' && object.priceTemplateType === 3) {
                classes = ['SO_TL']
            } else if (fieldName === 'SO') {
                classes = []
            }
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateInput(
                    'text',
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    onKeyPress,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    '',
                    warningMessages,
                    attributes,
                    withTooltip,
                )
            : null
        },
            
    File: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, onKeyPress, attributes) => (valudationMessages, warningMessages) =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateFileInput('text',
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    onKeyPress,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    warningMessages,
                    attributes)
                : null,

    IntField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder,
        tabIndex = '') =>
        (data, object, action, out, ref) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateInput('number',
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    null,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    tabIndex)
                : null,

    Phone: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GeneratePhoneInput('phone',
                    editability(object),
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    null,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style)
                : null,

    IntDecimalField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = { textAlign: 'center' },
        condition = ElementConditions.Enable,
        placeholder, 
        title,
    ) =>
        (data, object, action, out, ref) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateDecimalInput('number',
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    null,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    title,
                    )
                : null,

    IntFieldViewOrEdit: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder, display = null) =>
        (data, object, action, out, ref) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                return condition(object)
                    ? GenerateInput('number',
                        editability(object),
                        ref,
                        data,
                        ElementClassNames(object, data, classes),
                        action,
                        out,
                        valudationMessages,
                        placeholder,
                        null,
                        null,
                        null,
                        typeof style === 'function' ? style(object, data) : style)
                    : <div className="data_read">
                        <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data}
                        </text>
                    </div>;
            }
        },

    DecimalFieldViewOrEdit: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder, display = null) =>
        (data, object, action, out, ref) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                let customCondition = false;
                if (condition && typeof (condition) === 'function') {
                    customCondition = condition(object);
                }

                return customCondition
                    ? GenerateDecimalInput('number',
                        editability(object),
                        ref,
                        data,
                        ElementClassNames(object, data, classes),
                        action,
                        out,
                        valudationMessages,
                        placeholder,
                        null,
                        null,
                        null,
                        typeof style === 'function' ? style(object, data) : style)
                    : <div className="data_read">
                        <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data?.toLocaleString()}
                        </text>
                    </div>;
            }
        },

    CheckBox: (label,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) =>
        (data, object, action, out, ref) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateCheckBox(label,
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    typeof style === 'function' ? style(object, data) : style)
                : null,

    CheckBoxModern: (
        label,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) =>
        (data, object, action, out, ref, keyPress, attributes, onActionRecord, parentData) => valudationMessages => {
            const landingDayExist = parentData?.some(item => item.landing);
            const isSecondaryLanding = label === 'secondaryLanding';
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateCheckBoxModern(
                    label,
                    isSecondaryLanding ? landingDayExist : editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    typeof style === 'function' ? style(object, data) : style,
                    object,
                )
        : null },

    Radio: (label,
        name,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        field) =>
        (data, object, action, out, ref) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateRadio(label,
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    name,
                    typeof style === 'function' ? style(object, data) : style,
                    object,
                    field)
                : null,

    DateField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        min = null,
        max = null,
        date_format = Defines.Format.PrintDate,
        classes = ['centerText'],
        style = {},
        condition = ElementConditions.Enable,
        fieldName,
    ) =>
        (data, object, action, out, ref) => (valudationMessages, warningMessages) => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateDatePicker(editability(object),
                    date_format,
                    ref,
                    data,
                    typeof min === 'function' ? min(object.readyDate) : min,
                    max,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    warningMessages,
                    object,
                    fieldName,
                )
                : null
        },

    DueDateField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        min = null,
        max = null,
        date_format = Defines.Format.PrintDate,
        classes = ['centerText'],
        style = {},
        condition = ElementConditions.Enable,
        fieldName,
        hardFieldName
    ) =>
        (data, object, action, out, ref) => (valudationMessages, warningMessages) => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateDueDatePicker(editability(object),
                    date_format,
                    ref,
                    data,
                    typeof min === 'function' ? min(object.readyDate) : min,
                    max,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    warningMessages,
                    object,
                    fieldName,
                    hardFieldName
                )
                : null
        },

    TimeField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        timeFormat = Defines.Format.MomentTime,
        minuteStep = 15,
        use12Hours = false,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) =>
        (data, object, action, out, ref) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateTimePicker(editability(object),
                    timeFormat,
                    minuteStep,
                    use12Hours,
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    typeof style === 'function' ? style(object, data) : style)
                : null,

    DateFieldViewOrEdit: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        min = null,
        max = null,
        date_format = Defines.Format.PrintDate,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) =>
        (data, object, action, out, ref) =>
            (valudationMessages, warningMessages) => {
                if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                    let customCondition = true;
                    if (condition && typeof (condition) === 'function') {
                        customCondition = condition(object);
                    }

                    return customCondition
                        ? GenerateDatePicker(editability(object),
                            date_format,
                            ref,
                            data,
                            min,
                            max,
                            ElementClassNames(object, data, classes),
                            action,
                            out,
                            valudationMessages,
                            null,
                            typeof style === 'function' ? style(object, data) : style,
                            warningMessages
                        )
                        : <div className="data_read">
                            <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                                {data ? moment(data).format(Defines.Format.PrintDate) : ''}
                            </span>
                        </div>;
                }

                return null;
            },

    TimeFieldViewOrEdit: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, onKeyPress) => (valudationMessages, warningMessages) => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                let customCondition = true;
                if (condition && typeof (condition) === 'function') {
                    customCondition = condition(object);
                }

                return customCondition
                    ? GenerateInput('text',
                        editability(object),
                        ref,
                        data,
                        ElementClassNames(object, data, classes),
                        action,
                        out,
                        valudationMessages,
                        placeholder,
                        onKeyPress,
                        null,
                        null,
                        typeof style === 'function' ? style(object, data) : style,
                        warningMessages
                    )
                    : <div className="data_read">
                        <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {data}
                        </span>
                    </div>;
            }

            return null;
        },

    CurrencyField: (
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = { textAlign: 'center' },
        condition = ElementConditions.Enable,
        placeholder,
        fieldName) =>
        (data, object, action, out, ref) => valudationMessages => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateInputCurrency(
                    editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    null,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    object,
                    fieldName,
                )
                : null
        },

    DateTimeField: null,

    DecimalField: null,

    CurrencyFieldViewOrEdit: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder, display) =>
        (data, object, action, out, ref) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                return condition(object)
                    ? GenerateInputCurrency(
                        editability(object),
                        ref,
                        data,
                        ElementClassNames(object, data, classes),
                        action,
                        out,
                        valudationMessages,
                        placeholder,
                        null,
                        null,
                        null,
                        typeof style === 'function' ? style(object, data) : style)
                    : <div className="data_read">
                        <span className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data ? data.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }) : null}
                        </span>
                    </div>;
            }
        },

    AddressField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) =>
        (data, object, action, out, ref, onKeyPress) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <AddressField
                    type='address'
                    condition={editability(object)}
                    data={data}
                    object={object}
                    classes={ElementClassNames(object, data, classes)}
                    action={action}
                    out={out}
                    valudationMessages={valudationMessages}
                    onKeyPress={onKeyPress}
                    style={typeof style === 'function' ? style(object, data) : style}
                    placeholder='' />
                : null,

    MapField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, onKeyPress) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <MapField
                    type='roadmap'
                    condition={editability(object)}
                    data={data}
                    object={object}
                    classes={ElementClassNames(object, data, classes)}
                    action={action}
                    out={out}
                    valudationMessages={valudationMessages}
                    placeholder={placeholder}
                    onKeyPress={onKeyPress}
                    style={typeof style === 'function' ? style(object, data) : style} />
                : null,

    MultipleMarkersMapField: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, onKeyPress) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <MultipleMarkersMapField
                    type='roadmap'
                    condition={editability(object)}
                    data={data}
                    object={object}
                    classes={ElementClassNames(object, data, classes)}
                    action={action}
                    out={out}
                    valudationMessages={valudationMessages}
                    placeholder={placeholder}
                    onKeyPress={onKeyPress}
                    style={typeof style === 'function' ? style(object, data) : style} />
                : null,


    Comment: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, keyPress, attributes) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateTextArea(editability(object),
                    ref,
                    data,
                    ElementClassNames(object, data, classes),
                    action,
                    out,
                    valudationMessages,
                    placeholder,
                    null,
                    null,
                    null,
                    typeof style === 'function' ? style(object, data) : style,
                    attributes)
                : null,

    CommentLabel: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, keyPress, attributes) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className={ElementClassNames(object, data, [...classes, "data_help"])} style={typeof style === 'function' ? style(object, data) : style}>
                    {data || ''}
                </div>
                : null,

    SectionTitle: (label,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        placeholder) =>
        (data, object, action, out, ref, keyPress, attributes) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="form_fields">
                    <div className="section_title">
                        <text>{label}</text>
                    </div>
                    <div className="form_separator"></div>
                </div>
                : null,

    Select: (dataSource,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        selectedValueType) =>
        (data, object, action) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) &&
            <SelectElement
                dataSource={dataSource}
                parentValue={FindParentValue(object, parentValueType)}
                reloadOnParentChange={reloadOnParentChange}
                classes={ElementClassNames(object, data, classes)}
                style={typeof style === 'function' ? style(object, data) : style}
                condition={editability(object)}
                data={data}
                action={action}
                valudationMessages={valudationMessages}
                selectedValue={FindSelectedValueText(object, selectedValueType)} />,

    SelectViewOrEdit: (dataSource,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        display = null) =>
        (data, object, action) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                let customCondition = false;
                if (condition && typeof (condition) === 'function') {
                    customCondition = condition(object);
                }

                return customCondition
                    ? <SelectElement
                        dataSource={dataSource}
                        parentValue={FindParentValue(object, parentValueType)}
                        reloadOnParentChange={reloadOnParentChange}
                        classes={ElementClassNames(object, data, classes)}
                        style={typeof style === 'function' ? style(object, data) : style}
                        condition={editability(object)}
                        data={data}
                        action={action}
                        valudationMessages={valudationMessages} />
                    : <div className="data_read">
                        <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data}
                        </text>
                    </div>;
            }
        },

    SelectWithCache: (dataSource,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        selectedValueType) =>
        (data, object, action) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) &&
            <SelectElementWithCache
                dataSource={dataSource}
                parentValue={FindParentValue(object, parentValueType)}
                reloadOnParentChange={reloadOnParentChange}
                classes={ElementClassNames(object, data, classes)}
                style={typeof style === 'function' ? style(object, data) : style}
                condition={editability(object)}
                data={data}
                action={action}
                valudationMessages={valudationMessages}
                selectedValueType={selectedValueType}
                selectedValue={FindSelectedValueText(object, selectedValueType)} />,

    MultipleSelect: (dataSource,
        parentValueType,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        selectedValueType) =>
        (data, object, action) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) &&
            <SelectElement
                mode='multiple'
                dataSource={dataSource}
                parentValue={(object && parentValueType) ? object[parentValueType] : ''}
                classes={ElementClassNames(object, data, classes)}
                style={typeof style === 'function' ? style(object, data) : style}
                condition={editability(object)}
                data={data}
                action={action}
                valudationMessages={valudationMessages}
                selectedValue={FindSelectedValueText(object, selectedValueType)} />,

    Combo: (dataSource,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = { textAlign: 'center' },
        condition = ElementConditions.Enable,
        selectedValueType,
        fieldName,
        defaultValue,
        search = true,
    ) => (data, object, action) => valudationMessages => {
        if (fieldName === 'priceTemplateType') {
            if (object.batch === 1 || (object.batch === 2 && !!object.isIndividualPrice)) {
                editability = Ability.NoEditability;
            } else if (object.batch === 3 || (object.batch === 2 && !object.isIndividualPrice)) {
                editability = Ability.Editability;
            }
        }

        if (object.locationType !== 2 && fieldName === 'Date Change Needed') {
            displayability = Ability.NoDisplayability;
            object.dateChange = null;
        } else {
            displayability = Ability.Displayability;
        }

        return condition !== ElementConditions.Hidden && displayability(object, data) &&
            <SelectElement
                showSearch={search}
                dataSource={dataSource}
                parentValue={FindParentValue(object, parentValueType)}
                reloadOnParentChange={reloadOnParentChange}
                classes={ElementClassNames(object, data, classes)}
                style={typeof style === 'function' ? style(object, data) : style}
                condition={editability(object)}
                data={data}
                action={action}
                valudationMessages={valudationMessages}
                selectedValue={FindSelectedValueText(object, selectedValueType)}
                defaultValue={defaultValue} />
    },

    ComboViewOrEdit: (dataSource,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        display = null) =>
        (data, object, action) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                return condition(object)
                    ? <SelectElement
                        showSearch={true}
                        dataSource={dataSource}
                        parentValue={FindParentValue(object, parentValueType)}
                        reloadOnParentChange={reloadOnParentChange}
                        classes={ElementClassNames(object, data, classes)}
                        style={typeof style === 'function' ? style(object, data) : style}
                        condition={editability(object)}
                        data={data}
                        action={action}
                        valudationMessages={valudationMessages} />
                    : <div className="data_read">
                        <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data}
                        </text>
                    </div>;
            }
        },

    ComboAdd: (dataSource,
        addparams,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        defaultValue = false,
        selectedValueType,
        emptyOption,
        addNewElement = false,
    ) =>
        (data, object, action) => valudationMessages => {
            return condition !== ElementConditions.Hidden && displayability(object, data) &&
                <SelectElementAdd
                    dataSource={dataSource}
                    defaultValue={defaultValue}
                    addparams={addparams}
                    reloadOnParentChange={reloadOnParentChange}
                    parentValue={FindParentValue(object, parentValueType)}
                    classes={ElementClassNames(object, data, classes)}
                    style={typeof style === 'function' ? style(object, data) : style}
                    condition={editability(object)}
                    emptyOption={emptyOption}
                    data={data}
                    action={action}
                    valudationMessages={valudationMessages}
                    selectedValue={FindSelectedValueText(object, selectedValueType)}
                    addNewElement={addNewElement}
                />
        },

    ComboAddViewOrEdit: (dataSource,
        addparams,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        display = null) =>
        (data, object, action) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                return condition(object)
                    ? <SelectElementAdd
                        dataSource={dataSource}
                        addparams={addparams}
                        reloadOnParentChange={reloadOnParentChange}
                        parentValue={FindParentValue(object, parentValueType)}
                        classes={ElementClassNames(object, data, classes)}
                        style={typeof style === 'function' ? style(object, data) : style}
                        condition={editability(object)}
                        data={data}
                        action={action}
                        valudationMessages={valudationMessages} />
                    : <div className="data_read">
                        <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data}
                        </text>
                    </div>;
            }
        },

    ComboAddViewOrEditWithCash: (dataSource,
        addparams,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        display = null,
        selectedValueType) =>
        (data, object, action) => valudationMessages => {
            if (condition !== ElementConditions.Hidden && displayability(object, data)) {
                return condition(object)
                    ? <SelectElementAddWithCache
                        dataSource={dataSource}
                        addparams={addparams}
                        reloadOnParentChange={reloadOnParentChange}
                        parentValue={FindParentValue(object, parentValueType)}
                        classes={ElementClassNames(object, data, classes)}
                        style={typeof style === 'function' ? style(object, data) : style}
                        condition={editability(object)}
                        data={data}
                        action={action}
                        valudationMessages={valudationMessages}
                        selectedValue={FindSelectedValueText(object, selectedValueType)} />
                    : <div className="data_read">
                        <text className={ElementClassNames(object, data, classes)} style={typeof style === 'function' ? style(object, data) : style}>
                            {display && typeof display === 'function' ? display(object, data) : data}
                        </text>
                    </div>;
            }
        },

    ComboAddWithCache: (dataSource,
        addparams,
        parentValueType,
        reloadOnParentChange,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable,
        selectedValueType) =>
        (data, object, action, _fieldOut, _ref, _onKeyPress, _attributes, _onActionRecord, _parentData, _clicked, firstTimeAction) => valudationMessages => {
            return condition !== ElementConditions.Hidden && displayability(object, data) &&
                <SelectElementAddWithCache
                    dataSource={dataSource}
                    addparams={addparams}
                    reloadOnParentChange={reloadOnParentChange}
                    parentValue={FindParentValue(object, parentValueType)}
                    classes={ElementClassNames(object, data, classes)}
                    style={typeof style === 'function' ? style(object, data) : style}
                    condition={editability(object)}
                    data={data}
                    action={action}
                    valudationMessages={valudationMessages}
                    selectedValue={FindSelectedValueText(object, selectedValueType)}
                    firstTimeAction={firstTimeAction}
            />
        },

    ToolBarButton: (title,
        icon,
        displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) => (action, object) =>
            condition !== ElementConditions.Hidden && displayability(object) ?
                GenerateToolBarButton(
                    condition && editability(object),
                    typeof (title) === 'function' ? title(object) : title,
                    icon,
                    ElementClassNames(object, null, classes),
                    action,
                    typeof style === 'function' ? style(object) : style
                )
                : null,

    Button: (props, displayability = Ability.Displayability) => (data, object, action, out, ref, keyPress, attributes, onActionRecord, parentData, clicked) => {
        return displayability(object) ? GenerateColumnButton(object, props, data, onActionRecord, parentData, clicked) : () => null;
    },

    CancelColumnIndicator: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable,
        dataTitle = true,
        text,
        attributes = {},
        display = null) =>
        (data, object, action, out, ref, keyPress, attributes) => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateCancelIndicator(
                    display && typeof display === 'function' ? display(object, data) : (text || data),
                    dataTitle,
                    attributes)
                : null;
        },

    LatestCommentIndicator: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable,
        dataTitle = true,
        attributes = {}) =>
        (data, object, action, out, ref, keyPress, attributes) => () => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateLatestCommentIndicator(
                    data,
                    dataTitle,
                    attributes)
                : null;
        },

    ReceiveIndicator: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable,
        dataTitle = true,
        attributes = {}) =>
        (data, object, action, out, ref, keyPress, attributes) => () => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateReceiveIndicator(
                    object,
                    dataTitle,
                    attributes)
                : null;
        },

    ColumnBadge: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable,
        attributes = {},
        styles = {}) =>
        (data, object, action, out, ref, keyPress, attributes) => () => {
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateColumnBadge(
                    data,
                    true,
                    attributes)
                : null
        },

    SimpleColumnIndicator: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable,
        dataTitle = true,
        text,
        attributes = {},
        display = null) =>
        (data, object, action, out, ref, keyPress, attributes) => () => {
            const comment = text === 'Comment' && data && data.length && data[data.length - 1].comment;
            return condition !== ElementConditions.Hidden && displayability(object, data) ?
                GenerateSimpleColumnIndicator(
                    display && typeof display === 'function' ? display(object, data) : (comment || text || data),
                    dataTitle,
                    attributes)
                : null;
        },

    Avatar: (fileFieldName, displayability = Ability.Displayability,
        editability = Ability.Editability,
        condition = ElementConditions.Enable,
        noImage) =>
        (data, object, action) => validationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <AvatarUploader
                    max={5}
                    condition={editability(object)}
                    guid={data}
                    action={action}
                    originalName={object[fileFieldName]}
                    validationMessages={validationMessages}
                    classes={[]}
                    noImage={noImage} />
                : null,

    Download: (fileFieldName, width = 70, height = 70, displayability = Ability.Displayability,
        condition = ElementConditions.Enable) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <Download
                    guid={data}
                    width={width}
                    height={height}
                    filename={object[fileFieldName]} />
                : null,

    Color: (displayability = Ability.Displayability,
        editability = Ability.Editability,
        classes = [],
        style = {},
        condition = ElementConditions.Enable) =>
        (data, object, action) => valudationMessages =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <ColorElement
                    condition={editability(object)}
                    data={data}
                    object={object}
                    classes={ElementClassNames(object, data, classes)}
                    action={action}
                    valudationMessages={valudationMessages}
                    style={typeof style === 'function' ? style(object, data) : style} />
                : null,

    //toolbar fields
    ToolbarDate: (displayability = Ability.Displayability,
        condition = ElementConditions.Enable) =>
        (data, object) => () =>
            condition !== ElementConditions.Hidden && displayability(object, data) ?
                <div className="data_read">
                    {GenerateColumnDate(data)}
                </div>
                : null
};

const DefaultToolBars = (key, hasReference) => ({
    SaveBack: {
        Action: Action.SaveBack,
        Button: ElementTypes.ToolBarButton('Save & Back', 'save', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    SaveNew: {
        Action: Action.SaveNew,
        Button: ElementTypes.ToolBarButton('Save & New', 'save', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    Save: {
        Action: Action.Save,
        Button: ElementTypes.ToolBarButton('Save', 'save', o => o && !(o.orders?.length > 1 && o.orders.filter(o => o.batch === 3).length === o.orders?.length), Ability.Editability, ['button accent'])
    },
    Delete: {
        Action: Action.Delete,
        hasReference: hasReference,
        Button: ElementTypes.ToolBarButton('Delete', 'delete', o => o && o[key] > 0, Ability.Editability, ['button accent'])
    },

});

const ToolBars = (key, hasReference) => ({
    ...DefaultToolBars(key, hasReference),
    SaveClose: {
        Action: Action.SaveClose,
        Button: ElementTypes.ToolBarButton('Save & Close', 'save', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    Close: {
        Action: Action.Close,
        Button: ElementTypes.ToolBarButton('Close', '', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    Cancel: {
        Action: Action.Close,
        Button: ElementTypes.ToolBarButton('Cancel', '', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    CancelReason: {
        Action: Action.CancelReason,
        Button: ElementTypes.ToolBarButton('Cancel', 'cancel', o => o && o[key] > 0, Ability.Editability, ['button accent'])
    },
    Send: {
        Action: Action.SaveSend,
        Button: ElementTypes.ToolBarButton('Send', 'send', Ability.Displayability, Ability.Editability, ['button accent']),
        Value: { save: false }
    },
    SaveSend: {
        Action: Action.SaveSend,
        Button: ElementTypes.ToolBarButton('Save & Send', 'send', Ability.Displayability, Ability.Editability, ['button accent']),
        Value: { save: true }
    },
    Receive: {
        Action: Action.Save,
        Button: ElementTypes.ToolBarButton((o) => {
            return o.loadStatus !== 1 ? 'Update' : 'Receive'
        }, 'save', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    ChangePassword: {
        Action: Action.ChangePassword,
        Button: ElementTypes.ToolBarButton('Change Password', 'edit', Ability.Displayability, Ability.Editability, ['button accent'])
    },

    RequestAction: {
        Action: Action.SaveClose,
        Button: ElementTypes.ToolBarButton('Request', 'save', Ability.Displayability, Ability.Editability, ['button accent'])
    },

    Approve: {
        Action: Action.SaveClose,
        Button: ElementTypes.ToolBarButton('Approve', 'save', Ability.Displayability, Ability.Editability, ['button accent'])
    },
    Clone: {
        Action: Action.Clone,
        hasReference: hasReference,
        Button: ElementTypes.ToolBarButton('Clone', '', o => o && o[key] > 0, Ability.Editability, ['button accent'])
    },
    Request: {
        Action: Action.BeforeSave,
        Button: ElementTypes.ToolBarButton('Request', '', Ability.Displayability, Ability.Editability, ['button accent']),
        Buttons: {
            Request: {
                Action: Action.BeforeSave,
                Button: ElementTypes.ToolBarButton('Request', '', o => o?.[key] < 2, Ability.Editability, ['button accent']),
                Value: { [key]: 2 }
            },
            Requested: {
                Action: Action.BeforeSave,
                Button: ElementTypes.ToolBarButton('Requested', '', o => o?.[key] === 2, Ability.Editability, ['button accent']),
                Value: { [key]: 3 }
            },
            Confirmed: {
                Action: Action.BeforeSave,
                Button: ElementTypes.ToolBarButton('Confirmed', '', o => o?.[key] > 0 && o?.[key] < 4, Ability.Editability, ['button accent']),
                Value: { [key]: 4 }
            },
            Reject: {
                Action: Action.BeforeSave,
                Button: ElementTypes.ToolBarButton('Alternative', '', o => o?.[key] > 1, Ability.Editability, ['button accent']),
                Value: { [key]: -1 }
            }
        }
    }
});


/**
* @param toolbars string array, ToolBars object keys
* @param key primary key for conditional items
* @param title string, ToolBar title
*/
export const GenerateToolBars = (toolbars = ['default'], key = 'id', title = null, hasReference = false) => {
    const ToolBarItems = ToolBars(key, hasReference);
    let items = {};
    for (const i of toolbars) {
        if (i === 'default') {
            items = {
                ...DefaultToolBars(key, hasReference),
                ...items
            }

            continue;
        }

        if (ToolBarItems[i].Button) {
            items[i] = ToolBarItems[i];
        }
        if (ToolBarItems[i].Buttons) {
            items = { ...items, ...ToolBarItems[i].Buttons }
        }
    }

    const toolbar = [
        {
            Title: title,
            Items: items
        }
    ];

    return toolbar;
}