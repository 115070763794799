import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Scroll from '../../scroll/scroll';
import './index.css'
import { useClientHubNewRequest } from "../../../hook/hub/useClientHubNewRequest";
import { setNewRequestOrdersCount } from '../../../../store/actions/customerOrderAdmin/customerOrderAdmin';

const SubNavigation = ({ managedMenu, parentMenu, location, newRequestCount, total, state, setNewRequestOrdersCount }) => {
    const resource = 'customerOrderAdmin';
    const newRequestCountPageUrl = '/changerequest/customerorderadmin';
    let count = newRequestCount ? newRequestCount : total;
    if (newRequestCount < total) {
        count = total;
    };

    useClientHubNewRequest((message) => setNewRequestOrdersCount(resource, message.newRequestCount), 'notification');
    
    return (<>
        {useMemo(() => 
            <Scroll parentMenu={parentMenu}>
                <div className='subHeader_first' style={{ height: '32px' }}>
                    <ul>
                        {managedMenu && managedMenu?.map((item, i) => {
                            let selectedMenu = false;
                            const toItem = item.to?.toLowerCase();
                            selectedMenu = toItem === location;
                            return <li
                                key={i}
                                className={selectedMenu || (item.detail && location.startsWith(item.detail + '/'))
                                    ? 'selected' : ''} >
                                <Link to={item.to}>
                                    <text>{item.text}</text>
                                    {
                                        item.to === newRequestCountPageUrl && 
                                        location === newRequestCountPageUrl && 
                                        count > 0 && 
                                        <span className='new-request__count-container'>
                                            {count}
                                        </span>
                                    }
                                </Link>
                            </li>
                        })}
                    </ul>
                </div>
        </Scroll>, [count, location, managedMenu, newRequestCountPageUrl, parentMenu])}</>
    );
}

const mapStateToProps = state => ({
    newRequestCount: state.customerOrderAdmin.newRequestCount,
    total: state.customerOrderAdmin.total,
    loading: state.customerOrderAdmin.loading,
});

const mapDispatchToProps = (dispatch) => ({
    setNewRequestOrdersCount: (resource, newRequestCount) => dispatch(setNewRequestOrdersCount(resource, newRequestCount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubNavigation);
