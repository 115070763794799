import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import { getCustomerOrderAdmin } from '../../../../store/actions/customerOrderAdmin/customerOrderAdmin';
import { useClientHubNewRequest } from "../../../../codeless/hook/hub/useClientHubNewRequest";
import { CommonLabels } from '../../../../project/Defines';
import Download from '../../list/download/Download';
import Search from '../../list/filter/search';
import NewButton from '../../list/newButton';
import CreateGroupButton from '../../list/createGroupButton';
import Settings from '../../list/settings';
import HideShowMenuSwitch from '../../layout/hideShowMenuSwitch';
import ImportFromGSheet from '../../list/download/ImportFromGSheet';
import ExportToGSheet from '../../list/download/ExportToGSheet';

const ListView = props => {
    const pathname = window.location.pathname;
    const { total, getCustomerOrderAdmin } = props;
    const [newRequestCount, setNewRequestCount] = useState(total);
    const count = newRequestCount ? newRequestCount : total;

    useClientHubNewRequest((message) => setNewRequestCount(message.newRequestCount), 'notification');

    useEffect(() => {
        getCustomerOrderAdmin('customerOrderAdmin');
    }, [getCustomerOrderAdmin, newRequestCount, total]);

    return (<React.Fragment>
        {(props.columnConfig || props.showRefreshButton || props.download) &&
            <toolbar light='' shadow=''>
            { props.showTitleOnGrid && props.title && props.showTitle && <h2 style={{ margin: '2px' }}>{props.title}</h2>}
            { props.columnConfig && !props.hideSettings && <Settings screen={props.screen} columns={props.columns} /> }
            { props.allowNewButton && <NewButton addButtonText={props.addButtonText} allowNewButton={props.allowNewButton} onNewClick={props.onNewClick} /> }
            <separator vertical="vertical"></separator>
            {/* { props.HasSubNavigation && <div>
                    <Link style={{ position: 'relative'}} className={`subNavigationButton_cancel subNavigationButton_new_req ${pathname === '/loadplanning/changerequest' ? 'selectedMenu' : ''} `} to={'/loadplanning/changerequest'}>New Request
                        <span className='new-request__count-container'>{count === 0 ? '' : count}</span>
                    </Link>
                    <Link className={`subNavigationButton_cancel ${pathname === '/loadplanning/revision' ? 'selectedMenu' : ''} `} to={'/loadplanning/revision'}>Revision</Link>
                    <Link className={`subNavigationButton_cancel ${pathname === '/loadplanning/cancelorder' ? 'selectedMenu' : ''} `} to={'/loadplanning/cancelorder'}>Canceled</Link>
                </div>} */}
            { props.showRefreshButton ? <Tooltip title={CommonLabels.Buttons.Refresh}>
                <div className='button accent' onClick={() => props.loadData()}><text>{CommonLabels.Buttons.Refresh}</text></div>
                </Tooltip> : null}
                {props.download && <Download loadData={() => props.loadData()} /> }
                {props.importGSheet && <ImportFromGSheet refreshData={() => props.loadData()}/> }
            { props.exportGSheet && <ExportToGSheet /> }
            { props.allowCreateGroupButton && <CreateGroupButton onCreateGroupClick={props.onCreateGroupClick} /> }
            { props.search && <Search field={props.search} /> }
            { !props.isHiddenSwitch && <HideShowMenuSwitch /> }
            </toolbar>
        }
        {props.children}
    </React.Fragment>);
}

const mapStateToProps = state => ({
    total: state.customerOrderAdmin.total,
});

const mapDispatchToProps = (dispatch) => ({
getCustomerOrderAdmin: (resource, endPoint) => dispatch(getCustomerOrderAdmin(resource, endPoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
