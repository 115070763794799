import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Route, useLocation } from "react-router-dom";
import ScreenToolbar from './toolbar/screenToolbar';
import SectionGroup from './sectionGroup/sectionGroup';
import ToolBar from './toolbar/toolbar';
import Wrap from './login/wrap';
import Grid from '../../pages/grid';

const { TabPane } = Tabs;

const Content = props => {
    const [activeTab, setActiveTab] = useState("0");
    const [tabHistory, setTabHistory] = useState({});
    const location = useLocation();
    
    return <React.Fragment>
        {
            props.config && Object.keys(props.config).map((s, i) => {
                const section = props.config[s];
                switch (s) {
                    case 'Wrap':
                        return section.map((t, i) =>
                            <Wrap
                                key={i}
                                fields={t.Fields}
                                data={props.data}
                                dispatch={props.dispatch}
                                messages={props.messages}
                                messageDispatch={props.messageDispatch}
                                actionFromElement={props.actionFromElement}
                            />
                        );
                    case 'ScreenToolbar':
                        return section.map((t, i) =>
                            <ScreenToolbar
                                key={i}
                                noScroll={t.NoScroll}
                                isNone={t.IsNone}
                                isRight={t.IsRight}
                                fields={t.Fields}
                                data={props.data}
                                dispatch={props.dispatch}
                                messages={props.messages}
                                messageDispatch={props.messageDispatch}
                                actionFromElement={props.actionFromElement}
                            />
                        );
                    case 'View':
                        return props.data && section.Components &&
                            Object.keys(section.Components).map((c, i) =>
                                <React.Fragment key={c + i}>
                                    {props.config.View.Components[c].Component(
                                        c,
                                        props.data.hasOwnProperty(c) ? props.data[c] : props.data,
                                        props.dispatch,
                                        props.messages,
                                        props.messageDispatch,
                                        props.componentRefs,
                                        null,
                                        props.data)}
                                </React.Fragment>);
                    case 'SectionGroups':
                        return (<div className='form_container' key={i}>
                            {section.map((s, i) =>
                                <SectionGroup
                                    componentRefs={props.componentRefs}
                                    key={i}
                                    sections={s.Sections}
                                    components={s.Components}
                                    dispatch={props.dispatch}
                                    messages={props.messages}
                                    messageDispatch={props.messageDispatch}
                                    data={props.data}
                                    actionFromElement={props.actionFromElement}
                                    clicked={props.dataAction} />)
                            }
                        </div>);
                    case 'Tabstrip':
                        return <Route
                            key={i}
                            path={`${location.pathname}`}
                            render={({ match, history }) => {
                                const onChange = (key) => {
                                    setActiveTab(key);
                                    if (key === 'Price') {                                        
                                        history.replace(`/loadplanning/customerPrice?id=${props?.data?.id}`);
                                    } else {
                                        history.replace(`${location.pathname}`);
                                    }
                                };
                                let updatedSection = [];
                                if (location.pathname.includes('customerdetails')) {
                                    updatedSection = [
                                        ...section,
                                        {   Title: 'Price',
                                            NewPage: true
                                        }
                                    ];
                                } else {
                                    updatedSection = [...section];
                                };                                
                                
                                return <Tabs defaultActiveKey={activeTab} onChange={onChange} destroyInactiveTabPane={true}>
                                    {updatedSection.map((t, i) => {
                                        return <TabPane key={t.Title} tab={t.Title} disabled={(!props.data.id && i)} forceRender={true}>
                                            {t.List
                                                ? (activeTab === t.Title && Grid({
                                                    ...t,
                                                    handleSwitch: props.handleSwitch,
                                                    tabHistory,
                                                    setTabHistory,
                                                    ParentValue: props.data.id
                                                }))
                                                : <view><Content {...props} config={t.Form} /></view>}
                                        </TabPane>
                                    })}
                                </Tabs>
                            }}
                        />;
                    case 'ToolBars':
                        return section?.map((t, i) =>
                            <ToolBar
                                key={i}
                                toolBar={t}
                                isNone={t.IsNone}
                                clicked={props.dataAction}
                                data={props.data} />);
                    default:
                        return null;
                }
            })
        }
    </React.Fragment>;
};

export default Content;