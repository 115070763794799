import React from 'react';
import { useDispatch } from "react-redux";
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { setCustomerPriceType } from '../../../../store/actions/customers/customers';

const ChangePriceTemplateModal = ({ 
    changePriceTemplateModalIsOpen,
    setChangePriceTemplateModalIsOpen,
    customerId,
    priceTemplateType,
    setPriceTemplateType,
    prevPrice,
  }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const priceType = {
      SPOT: 1,
      PricePerPall: 2,
      PricePerRange: 3,
      PricePerPound: 4,
      spot: 1,
      priceperpall: 2,
      priceperrange: 3,
      priceperpound: 4,
  };

  const closeModal = () => {
    setChangePriceTemplateModalIsOpen(false);
    setPriceTemplateType(prevPrice);
    dispatch(setCustomerPriceType('customer', 'customerpricetype', customerId, priceType[prevPrice]));
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '250px',
      maxWidth: '320px',
      minHeight: '180px',      
      borderRadius: '8px',
      padding: '6px 12px',
      textAlign: 'center',
    },
  };

  const confirm = () => {    
    dispatch(setCustomerPriceType('customer', 'customerpricetype', customerId, priceType[priceTemplateType]));
    history.replace(`/loadplanning/customerPrice/${priceTemplateType.toLowerCase()}?id=${customerId}`);
    setPriceTemplateType(priceTemplateType);
    setChangePriceTemplateModalIsOpen(false);
  }

  return (
    <Modal
        isOpen={changePriceTemplateModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        >
        <div className='price_modal__container'>
          <div className='price_modal_text'>Are you sure you want to activate selected template?</div>
          <div>
            <button className='price-modal-button-confirm' type="submit" onClick={confirm}>Confirm</button>
            <button className='price-modal-button-cancel' type="submit" onClick={closeModal}>Cancel</button>
          </div>
        </div>  
    </Modal>
  );
};

export default ChangePriceTemplateModal;