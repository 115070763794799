import React, { useState } from 'react';
import Select from 'react-select'
import { Input, Checkbox } from 'antd';
import moment from 'moment';
import { stylesFilterMain, stylesFilter, stylesFilterTwoFilters } from './styles';

const Filters = props => {
    const {
        state,
        resource,
        handleSelectChange,
        handleSelectFilterUnassignedOrders,
        setSearchInput,
        setFilterInput,
        onlyTBDOrders,
        setOnlyTBDOrder,
        isBookingPage,
        searchInput,
    } = props;

    const [collapseState, toggleCollapseState] = useState(false);
    const [pickLocation, setPickLocations] = useState('');
    const [dropLocation, setDropLocations] = useState('');
    const [delNumber, setDelNumber] = useState('');
    const [apptStatus, setApptStatus] = useState('');
    const [stateName, setStateName] = useState('');
    const [region, setRegion] = useState('');
    const [readyDateFrom, setReadyDateFrom] = useState('');
    const [readyDateTo, setReadyDateTo] = useState('');
    const [checkboxSelected, setCheckboxSelected] = useState(true);

    const pickLocations = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.pickLocation : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.pickLocation : '' }
    });

    const dropLocations = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropLocation : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropLocation : '' }
    });

    let delNumbers = [];
    if (resource === 'load') {
        delNumbers = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
            return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inDeliverNummber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.inDeliverNummber : '' }
        });
    } else {
        delNumbers = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
            return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outDeliverNummber : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.outDeliverNummber : '' }
        });
    };

    const stateNames = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropStateName : '', 'label': order?.rowState?.type === 'order' ? order?.rowState?.orderData?.dropStateName : '' }
    });

    const readyDates = state[resource]?.loadsAssignedOrders && state[resource]?.loadsAssignedOrders.map(order => { 
        return {value: order?.rowState?.type === 'order' ? `${moment(order?.rowState?.orderData?.readyDate).week()}` : '', 'label': order?.rowState?.type === 'order' ? `WK - ${moment(order?.rowState?.orderData?.readyDate).week()}` : '' }
    });

    const uniquePickLocations = pickLocations?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const uniqueDropLocations = dropLocations?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const uniqueDelNumbers = delNumbers?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    // constant data for statuses
    const uniqueApptStatuses = [
        { value: 2, label: 'Open' },
        { value: 1, label: 'Change' },
        { value: 3, label: 'Pending' },
        { value: 4, label: 'Confirmed' },
        { value: 'All', label: 'All' },
    ];

    const uniqueStateNames = stateNames?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const uniqueReadyDates = readyDates?.filter((order, index, self) =>
        index === self.findIndex((t) => t.label === order.label && t.value === order.value))
    .filter(order => order.value !== '')
    .concat( { value: 'All', label: 'All' });

    const uniqueRegions = [
        { value: 'NORTHEAST', label: 'NORTHEAST' },
        { value: 'MOUNTAIN', label: 'MOUNTAIN' },
        { value: 'NORTHWEST', label: 'NORTHWEST' },
        { value: 'ARIZONA', label: 'ARIZONA' },
        { value: 'CALIFORNIA', label: 'CALIFORNIA' },
        { value: 'All', label: 'All' },
    ];

    const handleChangePick = option => {
        handleSelectChange(option.value, 'pick');
        setPickLocations(option);
    };

    const handleChangeDrop = option => {
        handleSelectChange(option.value, 'drop');
        setDropLocations(option);
    };

    const handleChangeDelNumber = option => {
        handleSelectChange(option.value, 'del');
        setDelNumber(option);
    };

    const handleChangeApptStatus = option => {
        handleSelectChange(option.value, 'apptStatus');
        setApptStatus(option);
    };

    const handleChangeStateName = option => {
        handleSelectChange(option.value, 'stateName');
        setStateName(option);
    };

    const handleChangeRegions = option => {
        handleSelectFilterUnassignedOrders(option.value, 'region');
        setRegion(option);
    };

    const handleChangeReadyDatesFrom = option => {
        setReadyDateFrom(option);
        handleSelectChange(option.value, 'readyDateFrom');
    };

    const handleChangeReadyDatesTo = option => {
        setReadyDateTo(option);
        handleSelectChange(option.value, 'readyDateTo');
    };

    const handleCheckboxSelected = () => {
        setCheckboxSelected(!checkboxSelected);
        setFilterInput('');
        setSearchInput('');
    };

    const handleTBDCheckboxSelected = () => {
        setOnlyTBDOrder(!onlyTBDOrders)
    }

    const clearAllFilters = () => {
        handleSelectChange('', 'default');
        handleSelectFilterUnassignedOrders('', 'default');

        setFilterInput('');
        setSearchInput('');
        setCheckboxSelected(true);
        setOnlyTBDOrder(false)

        setPickLocations(null);
        setDropLocations(null);
        setDelNumber(null);
        setApptStatus(null);
        setStateName(null);
        setRegion(null);
        setReadyDateFrom(null);
        setReadyDateTo(null);
    }

    return (       
        <div style={{display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgray' }}>
            <div className={`${collapseState ? 'show_collapse' : 'hide_collapse'} filters_container__collapse filter-search-container`}>
                <div className='filters__inner-container__collapse'>
                    <div className={`${collapseState ? 'filter-title__collapse__container__large' : ''} filter-title__collapse__container `} onClick={() => toggleCollapseState(!collapseState)} >
                        <icon className={`${collapseState ? 'filters-icon-grid__large' : 'filters-icon-grid__small'} `}>grid</icon>
                        <div 
                            className={`${collapseState ? 'large_title' : 'small_title'} filter-title__collapse`}>
                            Filters
                        </div>
                    </div>
                    <div className='horizontal-line'></div>
                    <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                        <div className='select-title__collapse'>Pick Location</div>
                        <Select
                                options={uniquePickLocations}
                                name="pickLocation"
                                placeholder="Pick"
                                value={pickLocation && pickLocation}
                                className='filter_pick'
                                noOptionsMessage={() => null}
                                onChange={option => handleChangePick(option)}
                                styles={stylesFilterMain}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                    </div>
                    <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                        <div className='select-title__collapse'>Delivery Location</div>
                        <Select
                                options={uniqueDropLocations}
                                name="dropLocation"
                                placeholder = "Delivery"
                                value={dropLocation && dropLocation}
                                className='filter_pick'
                                noOptionsMessage={() => null}
                                onChange={option => handleChangeDrop(option)}
                                styles={stylesFilterMain}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                    </div>
                    <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                        <div className='select-title__collapse'>Del number</div>
                        <Select
                                options={uniqueDelNumbers}
                                name="del"
                                placeholder = "DEL#"
                                value={delNumber && delNumber}
                                className='filter_pick'
                                noOptionsMessage={() => null}
                                onChange={option => handleChangeDelNumber(option)}
                                styles={stylesFilter}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                    </div>
                    {resource === 'outboundload' && <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                        <div className='select-title__collapse'>State</div>
                        <Select
                                options={uniqueStateNames}
                                name="stateName"
                                placeholder = "State"
                                value={stateName && stateName}
                                className='filter_pick'
                                noOptionsMessage={() => null}
                                onChange={option => handleChangeStateName(option)}
                                styles={stylesFilter}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                    </div>}
                    <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                        <div className='select-title__collapse'>Status</div>
                        <Select
                                options={uniqueApptStatuses}
                                name="apptStatus"
                                placeholder="Appt status"
                                value={apptStatus && apptStatus}
                                className='filter_pick'
                                noOptionsMessage={() => null}
                                onChange={option => handleChangeApptStatus(option)}
                                styles={stylesFilter}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                    </div>
                    {!isBookingPage && <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                        <div className='select-title__collapse'>Region</div>
                        <Select
                                options={uniqueRegions}
                                name="region"
                                placeholder="Region"
                                value={region && region}
                                className='filter_pick'
                                noOptionsMessage={() => null}
                                onChange={option => handleChangeRegions(option)}
                                styles={stylesFilter}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                    </div>}
                    <div className='two-filter-together'>
                        {resource === 'load' && <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                            <div className='select-title__collapse'>Ready Date From</div>
                            <Select
                                    options={uniqueReadyDates}
                                    name="readyDateFrom"
                                    placeholder="Ready Date From"
                                    value={readyDateFrom && readyDateFrom}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangeReadyDatesFrom(option)}
                                    styles={stylesFilterTwoFilters}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>}
                        {resource === 'load' && <div className={`${collapseState ? '' : 'hide-all-filters'} filter_container__select select__collapse`}>
                            <div className='select-title__collapse'>Ready Date To</div>
                            <Select
                                    options={uniqueReadyDates}
                                    name="readyDateTo"
                                    placeholder="Ready Date To"
                                    value={readyDateTo && readyDateTo}
                                    className='filter_pick'
                                    noOptionsMessage={() => null}
                                    onChange={option => handleChangeReadyDatesTo(option)}
                                    styles={stylesFilterTwoFilters}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                        </div>}
                    </div>
                </div>
                <div className={`${collapseState ? 'show_collapse' : 'hide-collapse hide-all-filters'} filters__main-container`}>
                    <div className='filter_container__select filter-checkbox_container'>
                        <div style={{ display: 'flex', marginTop: '16px'}}>
                            <Checkbox checked={checkboxSelected} onChange={() => handleCheckboxSelected()} />
                            {checkboxSelected ? <>
                                <div className='search_title'>Search</div>
                                <Input
                                    className='load__input_search'
                                    onChange={e => setSearchInput(e.target.value.toLowerCase())}
                                    value={searchInput}
                                    placeholder="PO#"
                                    type="text"
                                /> 
                            </> : null}
                            {!checkboxSelected ? <>
                                <div className='search_title'>Filter</div>
                                <Input
                                    className='load__input_search'
                                    onChange={e => setFilterInput(e.target.value.toLowerCase())}
                                    placeholder="PO#"
                                    type="text"
                                /> 
                            </> : null}
                        </div>
                        <div style={{ display: 'flex', marginTop: '16px'}}>
                            <div className='TBD-checkbox__title'>TBD</div>
                            <Checkbox checked={onlyTBDOrders} onChange={() => handleTBDCheckboxSelected()} />
                        </div>
                        <div style={{ display: 'flex', marginTop: '16px'}}>
                            <div onClick={() => clearAllFilters()} className='filters__clear-all'>Clear All</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
};

export default Filters;