import React, { useEffect, useState, useMemo } from 'react';
import SubNavigation from '../navigation/subNavigation';
import { Project } from '../../../../project/Project';

const SubToolbar = ({ menu, parentUrl, parentMenu, closeAllPopups }) => {
    const [managedMenu, setManagedMenu] = useState(null);
    useEffect(() => {
        setManagedMenu(Project.createMenu(menu, parentUrl));
    }, [menu, parentUrl]);
    const location = window.location.pathname.toLowerCase();

    let subMenuParentMenu = managedMenu?.find(m => m.sub);
    subMenuParentMenu = {
        ...subMenuParentMenu,
        to: `${subMenuParentMenu?.url}/${subMenuParentMenu?.sub[0].screen}`
    }

    const thirdMenu = menu?.find(m => m.items);
    if (managedMenu?.length && location.includes(thirdMenu?.screen)) {
        const index = managedMenu?.length && managedMenu?.findIndex(menu => menu.sub);
        managedMenu[index].to = `/${thirdMenu?.screen}/${thirdMenu?.items[0].screen}`;
    }
    const subMenu = Project.createMenu(thirdMenu?.items, thirdMenu?.screen)

    return (
        <toolbar className='subToolbar subHeaders' style={{ height: 'auto'}}>
            {useMemo(() => 
                <div className="nav_strip" launcher="" id="launcher">
                    {managedMenu ? 
                        <SubNavigation
                            managedMenu={managedMenu}
                            location={location}
                            closeAllPopups={closeAllPopups}
                            parentMenu={parentMenu}
                        /> : null
                    }
                    {subMenu && thirdMenu && location.includes(thirdMenu?.screen) ? 
                        <SubNavigation
                            managedMenu={subMenu}
                            location={location}
                            closeAllPopups={closeAllPopups}
                            parentMenu={subMenuParentMenu}
                        /> : null
                    }
                </div>, [managedMenu, location, closeAllPopups, parentMenu, subMenu, thirdMenu, subMenuParentMenu])
            }
        </toolbar>
    );
};

export default SubToolbar;